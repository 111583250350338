<script setup lang="ts">
const images = [
	{
		width: 56,
		height: 56
	},
	{
		width: 23,
		height: 23
	},
	{
		width: 43,
		height: 43
	},
	{
		width: 43,
		height: 43
	},
	{
		width: 56,
		height: 56
	}
];
</script>

<template>
	<div class="blink-holder">
		<div v-for="(imageNumber, index) in [1, 2, 3, 1, 1]" :key="index" :class="`blink blink-${index + 1}`">
			<NuxtImg
				:width="images[index]?.width"
				:height="images[index]?.height"
				:src="`/nuxt-img/promotions/blinks/blink-${imageNumber}.png`"
				alt="blink"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.blink-holder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

:deep(.blink) {
	position: absolute;
	width: max-content;
}

:deep(.blink-1) {
	top: 20px;
	right: 141px;
	animation: neon 3s linear infinite;

	@include media-breakpoint-down(sm) {
		top: 14px;
		right: 76px;
	}

	img {
		@include media-breakpoint-down(sm) {
			width: 32px;
			height: 32px;
		}
	}
}

:deep(.blink-2) {
	top: 40px;
	right: 110px;
	animation: neon 3s linear 2s infinite;

	@include media-breakpoint-down(sm) {
		top: 0;
		right: 20px;
	}

	img {
		@include media-breakpoint-down(sm) {
			width: 32px;
			height: 32px;
		}
	}
}

:deep(.blink-3) {
	top: 177px;
	right: 55px;
	animation: neon 2s linear 1s infinite;

	@include media-breakpoint-down(sm) {
		top: 80px;
		right: 27px;
	}

	img {
		@include media-breakpoint-down(sm) {
			width: 24px;
			height: 24px;
		}
	}
}

:deep(.blink-4) {
	top: 26px;
	right: 4px;
	animation: neon 2s linear 1s infinite;

	@include media-breakpoint-down(sm) {
		top: 47px;
		right: 0px;
	}

	img {
		@include media-breakpoint-down(sm) {
			width: 24px;
			height: 24px;
		}
	}
}

:deep(.blink-5) {
	top: 26px;
	left: 4px;
	animation: neon 2s linear 3s infinite;

	@include media-breakpoint-down(sm) {
		top: 60px;
		left: 4px;
	}

	img {
		@include media-breakpoint-down(sm) {
			width: 24px;
			height: 24px;
		}
	}
}

@keyframes neon {
	20% {
		opacity: 1;
	}
	80% {
		opacity: 0.1;
	}
}
</style>
